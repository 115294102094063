/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import Vuex from 'vuex';
import preference from './modules/preference';
import auth from './modules/auth';
import feed from './modules/feed';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    preference,
    auth,
    feed,
  },
});
