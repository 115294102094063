import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";
import Home from "../components/Home.vue";
import PhoneSignIn from "../components/PhoneSignIn.vue";
import Onboard from "../components/Onboard.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/signin",
    name: "signin",
    component: PhoneSignIn,
  },
  {
    path: "/onboard",
    name: "onboard",
    component: Onboard,
    beforeEnter: (to, from, next) => {
      if (firebase.auth().currentUser) next();
      else next("/");
    },
  },
  {
    path: "/",
    name: "home",
    component: Home,
  },
];

export default new VueRouter({ mode: "history", routes });
