/* eslint-disable no-shadow */
const state = {
  city: '',
  areas: [],
  language: '',
  gigs: [],
};
const getters = {
  getLocale: (state) => state,
};
const mutations = {};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
