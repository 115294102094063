<template>
  <v-container
  max-width="520">
      <v-file-input
      v-model="selectedFile"
      accept="image/png, image/jpeg"
      :label="formLabel"
      :show-size="1024"
      dark
      prepend-icon="mdi-camera"
      @change="setupCropper"></v-file-input>
      <v-row v-if="objectUrl">
        <v-col class="text-center" cols="12" sm="6">
          <div class="overline" dark>Original</div>
          <div class="image-container elevation-4">
              <img
              class="image-preview"
              ref="source"
              :src="objectUrl"/>
          </div>
          <div class="d-flex justify-center">
            <v-btn
            icon
            small
            dark
            @click="resetCropper">
            <v-icon small>mdi-aspect-ratio</v-icon>
            </v-btn>
            <v-btn
            icon
            dark
            small
            @click="rotateLeft">
            <v-icon small>mdi-rotate-left</v-icon>
            </v-btn>
            <v-btn
            icon
            dark
            small
            @click="rotateRight">
            <v-icon small>mdi-rotate-right</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col class="text-center" cols="12" sm="6">
          <div class="overline" dark>Preview</div>
          <div class="image-container elevation-4">
              <img class="image-preview"
              :src="previewCropped"/></div>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Cropper from 'cropperjs';
import debounce from 'lodash/debounce';

export default {
  data() {
    return {
      cropper: null,
      objectUrl: null,
      previewCropped: null,
      selectedFile: null,
      debouncedUpdatePreview: debounce(this.updatePreview, 257),
    };
  },
  props: [
    'formLabel',
  ],
  methods: {
    resetCropper() {
      this.cropper.reset();
    },
    rotateLeft() {
      this.cropper.rotate(-90);
    },
    rotateRight() {
      this.cropper.rotate(90);
    },
    setupCropper(selectedFile) {
      if (this.cropper) {
        this.cropper.destroy();
      }

      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl);
      }

      if (!selectedFile) {
        this.cropper = null;
        this.objectUrl = null;
        this.previewCropped = null;
        return;
      }

      this.objectUrl = window.URL.createObjectURL(selectedFile);
      this.$nextTick(this.setupCropperInstance);
    },
    setupCropperInstance() {
      this.cropper = new Cropper(this.$refs.source, {
        aspectRatio: 16 / 9,
        crop: this.debouncedUpdatePreview,
      });
    },
    updatePreview() {
      const canvas = this.cropper.getCroppedCanvas();
      this.previewCropped = canvas.toDataURL('image/jpeg');
    },
    pictureData() {
      this.$emit('listingPicture', this.cropper.getCroppedCanvas({
        width: 1024,
        height: 576,
        minWidth: 256,
        minHeight: 256,
        maxWidth: 4096,
        maxHeight: 4096,
        fillColor: '#fff',
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      }));
      // this.$emit('fileIsSelected',this.selectedFile)
    },
    clearData() {
      this.$emit('listingPicture', null);
    },
  },
  updated() {
    // eslint-disable-next-line no-console
    if (this.cropper) this.pictureData();
    else this.clearData();
  },
  // watch: {
  //   'selectedFile' : function() {
  //   }
  // }
};
</script>

<style  scoped>
.image-container{
  display: inline-block;
  max-width: 270px;
  }

.image-preview {
  display: block;
  max-height: 229px;
  max-width: 100%;
}
</style>

<style lang="sass">
@import '../../node_modules/cropperjs/dist/cropper.css'
</style>
