/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
import firebase from 'firebase/app';
import 'firebase/auth';
// import { mapGetters } from 'vuex';
export const firebaseAuthMixins = {
  data() {
    return {
      phone: '',
      verifyCode: '',
    };
  },
  mounted() {
    // const vm = this;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      size: 'invisible',
      callback() {
        // eslint-disable-next-line no-console
        console.log('recaptcha verified');
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
        // vm.onSignInSubmit()
        window.recaptchaVerifier.render()
          .then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('recaptcha error: ', error);
          });
      },
      'expired-callback': function () {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        window.recaptchaVerifier.render().then((widgetId) => {
          firebase.auth.RecaptchaVerifier.reset(widgetId);
        });
      },
    });
  },
  computed: {
    // ...mapGetters({
    //     verifyCode: 'auth/verifyCode',

    // }),
    // verifyCode() {
    //     return this.$store.getters['auth/verifyCode']
    // },
    showVerifyCode() {
      return this.$store.getters['auth/showVerifyCode'];
    },
    verifyError() {
      return this.$store.getters['auth/verifyError'];
    },
    phoneExists() {
      return this.$store.getters['auth/phoneExists'];
    },
    phoneSearched() {
      return this.$store.getters['auth/phoneSearched'];
    },
    smsFailed() {
      return this.$store.getters['auth/smsFailed'];
    },
    user() {
      return this.$store.getters['auth/userId'];
    },
  },
};
