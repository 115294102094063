<template>
  <div>
    <v-navigation-drawer v-model="sidebar" app disable-resize-watcher dark>
      <v-list>
        <v-list-item>
          <v-list-item-content>Gooday</v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.stop="sidebar =!sidebar">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>Home</v-list-item-content>
        </v-list-item>
        <v-list v-if="!authed">
          <v-list-item
            v-for="(item,index) in outMenuItems"
            :key="index"
            :to="{ name: item.link }"
            exact
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item
            v-for="(item,index) in inMenuItems"
            :key="index"
            :to="{ name: item.link }"
            exact
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list-item v-if="authed" @click="signOut">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>SignOut
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="#c21e81">
      <span class="hidden-md-and-up">
        <v-app-bar-nav-icon x-large @click="sidebar = !sidebar"></v-app-bar-nav-icon>
      </span>
      <div class="d-flex align-left">
        <v-img
          alt="Gooday Logo"
          class="shrink my-1"
          contain
          src="../assets/Banner-Gooday-Cloud-TM-logo.png"
          transition="scale-transition"
          width="50"
        />
      </div>
      <v-spacer></v-spacer>

      <v-btn  class="ma-1 hidden-sm-and-down" to="/">
        <v-icon left>mdi-home</v-icon>
        Home
      </v-btn>
      <v-btn v-if="authed"  class="ma-1 hidden-sm-and-down" to="/onboard">
        <v-icon left>mdi-account-plus</v-icon>
        Registration
      </v-btn>
      <v-btn v-if="!authed"  class="ma-1 hidden-sm-and-down" to="/signin">
        <v-icon left>mdi-login</v-icon>
        Onboard
      </v-btn>
      <v-btn
        v-if="authed"
        class="ma-1 hidden-sm-and-down"
        @click="signOut"
      >
        <v-icon left>mdi-logout</v-icon>
        SignOut
      </v-btn>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  components: {},
  computed: {

    authed() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    outMenuItems() {
      return [
        {
          title: 'onboard',
          link: 'signin',
          icon: 'mdi-login',
        },
      ];
    },
    agent() {
      return this.$store.getters['auth/email'];
    },
    inMenuItems() {
      return [
        {
          title: 'onboard',
          link: 'onboard',
          icon: 'mdi-account-plus',
        },
      ];
    },
  },
  methods: {
    test() {},
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    signOut() {
      this.$store.dispatch('auth/signOut');
    },
  },
  data: () => ({
    sidebar: false,
  }),
};
</script>
