import Vue from 'vue';
import firebase from 'firebase/app';
import axios from 'axios';
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueGooglePlaces from 'vue-google-places';
import Viewer from 'v-viewer';
import App from './App.vue';
import router from './router/router';
import { store } from './store/store';
import vuetify from './plugins/vuetify';
import 'firebase/auth';
import { firebaseConfig } from './firebase';

Vue.config.productionTip = false;

// initialize firebase
firebase.initializeApp(firebaseConfig);
// firebase.auth().useDeviceLanguage();
axios.defaults.baseURL = 'https://goodayon-47465.firebaseio.com';
axios.defaults.headers.get.Accepts = 'application/json';
Vue.use(Vuelidate);
Vue.use(firebase);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAP_APIKEY,
    libraries: 'places',
  },
});
Vue.use(VueGooglePlaces);
Vue.use(Viewer);
// console.log('before app');
const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
});
// console.log('before mount');
// mount app after firebase is initialized
firebase.auth().onAuthStateChanged(
  () => {
    store.commit('auth/verifyLogin');
    app.$mount('#app');
  },
);
// console.log('mounted');
