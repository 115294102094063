/* eslint-disable no-shadow */
import firebase from 'firebase/app';
import 'firebase/firestore';

const state = {
  feed: [],
};
const getters = {
  getFeed: (state) => state.feed,
};
const mutations = {
  setFeed: (state, payload) => { state.feed = payload; },
};
const actions = {
  refreshFeed({ commit }, myCity) {
    // const myCity = this.$store.getters['auth/city']
    // const myHood = this.hoods[this.selectedHood]
    const myFeed = [];
    // gooday collection backend reference
    const db = firebase.firestore();
    const goodayRef = db.collection('goodays');
    goodayRef.where('city', '==', myCity)
      .limit(100).get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          myFeed.push(doc.data());
        });
        commit('setFeed', myFeed);
        // eslint-disable-next-line no-console
        // console.log(myFeed)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
