/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable import/no-useless-path-segments */

import firebase from 'firebase/app';
import router from '../../../src/router/router';
import 'firebase/auth';
import 'firebase/firestore';

const state = {
  fbaseAuth: false,
  userId: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  city: null,
  inTraining: null,
  // verifyCode: '',
  showVerifyCode: false,
  verifyError: false,
  smsFailed: false,
  phoneExists: null,
  phoneSearched: null,
  routedNewUser: false,
  badgeNumber: null,
  providers: [],
  customers: [],
  customersCount: 0,
  providersCount: 0,
};
const getters = {
  userId: (state) => state.userId,
  phone: (state) => state.phone,
  email: (state) => state.email,
  inTraining: (state) => state.inTraining,
  isAuthenticated: (state) => state.fbaseAuth,
  userName: (state) => `${state.firstName} ${state.lastName}`,
  // verifyCode: (state) => {return state.verifyCode},
  showVerifyCode: (state) => state.showVerifyCode,
  verifyError: (state) => state.verifyError,
  phoneExists: (state) => state.phoneExists,
  phoneSearched: (state) => state.phoneSearched,
  smsFailed: (state) => state.smsFailed,
  routedNewUser: (state) => state.routedNewUser,
  city: (state) => state.city,
  badgeNumber: (state) => state.badgeNumber,
  providers: (state) => state.providers,
  providersCount: (state) => state.providersCount,
  customers: (state) => state.customers,
  customersCount: (state) => state.customersCount,
};
const mutations = {
  storeUserInfo: (state, authData) => {
    state.userId = authData.userId;
    state.email = authData.email;
    state.phone = authData.phone;
    state.firstName = authData.firstName;
    state.lastName = authData.lastName;
    state.city = authData.city;
    state.inTraining = authData.inTraining;
    state.badgeNumber = authData.badgeNumber;
  },
  // clearAuth(state) {
  //     state.userId = null
  //     state.email = null
  //     state.fbaseAuth = false
  //     state.phone = null
  //     state.firstName = null
  //     state.lastName = null
  // },
  verifyLogin(state) {
    (firebase.auth().currentUser && state.badgeNumber)
      ? state.fbaseAuth = true : state.fbaseAuth = false;
  },
  setUserId(state, userId) {
    state.userId = userId;
  },
  smsSuccessUpdate(state) {
    state.showVerifyCode = true;
    state.verifyError = false;
    state.smsFailed = false;
  },
  signInRouteUpdate(state) {
    state.showVerifyCode = false;
    state.verifyError = null;
    state.smsFailed = null;
    state.routedNewUser = true;
  },
  smsFailureUpdate(state) {
    state.smsFailed = true;
  },
  signInFailureUpdate(state) {
    state.verifyError = true;
    state.showVerifyCode = false;
    // state.verifyCode = ''
  },
  phoneSearchUpdate(state) {
    state.phoneSearched = true;
  },
  phoneExistence(state, doc) {
    doc.exists ? state.phoneExists = true : state.phoneExists = false;
  },
  providersCountUpdate(state, count) {
    state.providersCount = count;
  },
  providersLocUpdate(state, locs) {
    state.providers = [...locs];
  },
  customersCountUpdate(state, count) {
    state.customersCount = count;
  },
  customersLocUpdate(state, locs) {
    state.customers = [...locs];
  },
  logOutCleanUp(state) {
    state.fbaseAuth = false;
    state.userId = null;
    state.firstName = null;
    state.lastName = null;
    state.email = null;
    state.inTraining = null;
    state.phone = null;
    state.city = null;
    state.showVerifyCode = false;
    state.verifyError = false;
    state.smsFailed = false;
    state.phoneExists = null;
    state.phoneSearched = null;
    state.badgeNumber = null;
  },
};
const actions = {
  smsAuthenticate({ dispatch }, phoneNumber) {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() =>
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // ...
      // New sign-in will be persisted with session persistence.
        dispatch('sendSMSToken', phoneNumber))
      .catch((error) => {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // eslint-disable-next-line no-console
        console.log('sms auth error code :', error.code, ' message :', error.message);
      });
  },
  sendSMSToken({ commit }, phoneNumber) {
    firebase.auth().signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // eslint-disable-next-line no-console
        console.log('SMS is sent!');
        commit('smsSuccessUpdate');
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        // eslint-disable-next-line no-console
        console.log('error sending sms', error);
        // eslint-disable-next-line no-alert
        alert('Error : SMS code is not sent!\n', error);
        commit('smsFailureUpdate');
        // window.recaptchaVerifier.render().then(function(widgetId) {
        //     firebase.auth.RecaptchaVerifier.reset(widgetId);
        // })
      });
  },
  signIn({ dispatch, commit }, loginData) {
    window.confirmationResult.confirm(loginData.verifyCode).then((result) => {
      // User signed in successfully.
      commit('setUserId', result.user.uid);
      // eslint-disable-next-line no-console
      // console.log('signin userid', firebase.auth().currentUser.uid);
      dispatch('checkLogin', loginData.badgeNumber);
      // firebase.database().ref('/users/'+ this.user).set(userData)
      // ...
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      // ...
      // eslint-disable-next-line no-console
      console.log('bad verification code, try again', error.message);
      // eslint-disable-next-line no-alert
      alert('The verification code is wrong! Try again');
      commit('signInFailureUpdate');
    });
  },
  storeUser({ state }, userData) {
    if (!state.userId) return;
    // eslint-disable-next-line no-console
    // console.log('recording new user...')
    firebase.firestore().collection('gdoAdmins').doc(state.userId).set(userData)
      .then(() => {
        // eslint-disable-next-line no-console
        // console.log('new user registration success!')
        router.push('/feed');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  },
  phoneLookup({ commit, state }) {
    firebase.firestore().collection('gdoAdmins').doc(state.userId).get()
      .then((doc) => {
      // eslint-disable-next-line no-console
      // console.log('phone search matched user')
      // eslint-disable-next-line max-len
      // const matchedUsers = Object.values(snapshot.val()).filter(element => element.phone == this.phone)
        commit('phoneSearchUpdate');
        commit('phoneExistence', doc);
        // matchedUsers.length == 0 ? this.phoneExists = false : this.phoneExists = true
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('phonelookup - error searching phone number', error);
      });
  },
  loadUserData({ commit }, onboardAuth) {
    // var userRef = firebase.database().ref('/users/'+ currentUser.uid)
    // eslint-disable-next-line no-console
    // console.log("updateUserData")
    firebase.firestore().collection('gdoAdmins').doc(onboardAuth.badgeNumber).get()
      .then((doc) => {
      // eslint-disable-next-line no-console
        // console.log('snapshot', doc);
        commit('phoneSearchUpdate');
        commit('phoneExistence', doc);
        if (doc.exists) {
          commit('storeUserInfo', {
            userId: onboardAuth.uid,
            email: doc.data().email,
            phone: doc.data().phone,
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            city: doc.data().city,
            inTraining: doc.data().inTraining == null ? false : doc.data().inTraining,
            badgeNumber: onboardAuth.badgeNumber,
          });
          commit('verifyLogin');
          router.push('/onboard');
          return;
        }
        // eslint-disable-next-line no-alert
        alert('Onboarding agent code is wrong! Please note your pin code is case sensitive. Try again!');
        commit('logOutCleanUp');
        router.push('/').catch(
          // eslint-disable-next-line no-console
          (error) => console.log(error),
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('loadUserData gdoAdmins - error searching phone number', error);
      });
  },
  checkLogin({ dispatch }, badgeNumber) {
    // console.log('check login for badge number: ', badgeNumber);
    if (badgeNumber == null) return;
    const loggedUser = firebase.auth().currentUser;
    // eslint-disable-next-line no-console
    // console.log(loggedUser);
    if (!loggedUser) return;
    // user is logged in - get userdata from from firebase and update state
    dispatch('loadUserData', { uid: loggedUser.uid, badgeNumber });
  },
  signOut({ commit }) {
    firebase.auth().signOut().then(
      // eslint-disable-next-line no-console
      () => console.log('signed out'),
    )
      .catch(
        // eslint-disable-next-line no-console
        (error) => console.log(error),
      );
    commit('logOutCleanUp');
    router.push('/')
    // eslint-disable-next-line no-console
      .then((response) => console.log(response));
  },
  appUsers({ commit }) {
    firebase.firestore().collection('users').get()
      .then((querySnapshot) => {
        const locations = [];
        const customersLocations = [];
        let providersCount = 0;
        let customersCount = 0;
        querySnapshot.forEach((doc) => {
          if (doc.data().serviceProvider === true) {
            locations.push({
              lat: doc.data().geoLoc.geopoint.latitude,
              lng: doc.data().geoLoc.geopoint.longitude,
            });
            providersCount += 1;
          } else {
            customersLocations.push({
              lat: doc.data().geoLoc.geopoint.latitude,
              lng: doc.data().geoLoc.geopoint.longitude,
            });
            customersCount += 1;
          }
        });
        commit('providersLocUpdate', locations);
        commit('providersCountUpdate', providersCount);
        commit('customersLocUpdate', customersLocations);
        commit('customersCountUpdate', customersCount);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
