/* eslint-disable quotes */
<template>
  <v-app style="background: #424242">
    <Header />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Header from './components/Header.vue';

export default {
  name: 'App',

  components: {
    Header,
  },
  methods: {},
  created() {
    // this.$i18n.locale = this.$store.getters['language/getLocale'];
    // eslint-disable-next-line no-console
    // console.log(this.$i18n.locale)
    this.$store.dispatch('auth/checkLogin');
  },
};
</script>
<style>
@font-face {
       font-family: "menbere";
       src: url("../fonts/MenbereReg13.ttf");
   }
.amharic {
    font-family: "menbere";
}
</style>
